import { createHttpEndpoint } from '../../utils'

import type { GetSkinnyBanner } from './branding.d'

/**
 * SkinnyBannerView
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/branding-web-api/definition#operation/get-branding-skinny-banner}
 */
export const getSkinnyBanner = createHttpEndpoint<GetSkinnyBanner.Response>({
  method: 'GET',
  operationId: 'skinnyBannersFetch',
  path: '/bm/branding/skinny-banner',
})

/**
 * Get Student Discount page settings
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/branding-web-api/definition#tag/student-discount/operation/get-student-discount}
 */
export const getStudentDiscount = createHttpEndpoint({
  method: 'GET',
  operationId: 'getStudentDiscount',
  path: '/bm/branding/student-discount',
})
