import { defineStore } from 'pinia'

export const useBrandingStore = defineStore('branding', {
  state: () => ({
    isSkinnyBannerDisplayed: true,
  }),
  actions: {
    closeSkinnyBanner() {
      this.isSkinnyBannerDisplayed = false
    },
  },
})
