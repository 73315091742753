import { computed } from 'vue'

import { brandingAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { omit } from '@backmarket/utils/object/omit'

/**
 * Returns the cached SkinnyBanner data for the given page and id
 *
 * @example
 * const { data: skinnyBanner } = await useSkinnyBanner('home', 1)
 *
 */
export async function useSkinnyBanner(page: string, id?: number | null) {
  const { data } = await useHttpFetch(brandingAPI.getSkinnyBanner)

  return computed(() => {
    let banners = (data.value || []).filter(({ locations = [] }) =>
      locations.includes(page),
    )
    if (id) {
      banners = banners.filter(({ id: bannerId }) => bannerId === id)
    }

    const [bannerToDisplay = null] = banners

    return bannerToDisplay ? omit(bannerToDisplay, ['locations', 'id']) : null
  })
}
