<template>
  <RevBanner
    v-if="brandingStore.isSkinnyBannerDisplayed && skinnyBanner !== null"
    :close-alternative-text="i18n(translations.closeAlternativeText)"
    :cta="skinnyBanner.cta_text"
    :label="skinnyBanner.text"
    :name="skinnyBanner.name"
    :to="skinnyBanner.link"
    @click="sendMarketingData"
    @close="closeBanner"
  />
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevBanner } from '@ds/components/Banner'

import { useSkinnyBanner } from '../../composables/useSkinnyBanner'
import { useBrandingStore } from '../../store'

import translations from './SkinnyBanner.translations'

const i18n = useI18n()
const { trackClick } = useTracking()
const brandingStore = useBrandingStore()

const props = defineProps<{
  page: string
  bannerId?: number | null
}>()

const skinnyBanner = await useSkinnyBanner(props.page, props.bannerId)

function sendMarketingData() {
  if (skinnyBanner.value) {
    trackClick({
      name: skinnyBanner.value.name,
      zone: 'skinnyBanner',
    })
  }
}

function closeBanner() {
  brandingStore.closeSkinnyBanner()
}
</script>
